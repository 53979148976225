'use client';

import { PersonalizationConfig } from '@/config-schema/home-page';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { useRecommendedForUser } from '../hooks/use-recommended-for-user';
import { useRecommendedProducts } from '../hooks/use-recommended-products';
import {
  RecommendedProductsBannerVariants,
  RecommendedProductsDataItemTypes,
} from '../types';
import { RecommendedBannerV2 } from './recommended-banner-v2';
import { RecommendedForUserFeatureFlag } from './recommended-for-user-feature-flags';
import { RecommendedSkeleton } from './recommended-skeleton';

interface RecommendedForUserBannerProps {
  config: PersonalizationConfig['config'];
  fallbackData: PersonalizationConfig['fallbackData'];
  variant?: RecommendedProductsBannerVariants;
}

export function RecommendedForUserBanner({
  config,
  fallbackData,
  variant = 'product_details',
}: RecommendedForUserBannerProps) {
  return (
    <RecommendedForUserFeatureFlag>
      <RecommendedForUserBannerWrapper
        config={config}
        fallbackData={fallbackData}
        variant={variant}
      />
    </RecommendedForUserFeatureFlag>
  );
}

function RecommendedForUserBannerWrapper({
  config,
  fallbackData,
  variant,
}: RecommendedForUserBannerProps) {
  const { data, isFetched, isError } = useRecommendedForUser();
  const isGcMaintenance = useFlagsReady('rc_gift_card_maintenance');
  const recommendedForUser =
    data === undefined || data.length === 0
      ? convertDataShape(fallbackData)
      : data;

  const filteredRecommendedForUser = recommendedForUser?.filter(
    ({ type }) =>
      !(type === RecommendedProductsDataItemTypes.GiftCard && isGcMaintenance),
  );

  const results = useRecommendedProducts(
    filteredRecommendedForUser,
    !!data && isFetched && !isError,
  );

  const showSkeleton = results.some((result) => result.isFetched === false);

  const shouldHide = results.every(
    (result) => result.isSuccess === false || !result.data,
  );
  if (shouldHide) {
    return null;
  }

  if (showSkeleton) {
    return <RecommendedSkeleton title={config.title} />;
  }

  return (
    <RecommendedBannerV2
      title={config.title}
      variant={variant}
      results={results}
      recommendedProducts={filteredRecommendedForUser}
    />
  );
}

function convertDataShape(fallbackData: PersonalizationConfig['fallbackData']) {
  return fallbackData.map(({ id, orderItemType, score }) => ({
    id,
    score,
    type: orderItemType,
  }));
}
