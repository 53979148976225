import { useAppEnv } from '@/store/store';

export const useTravelImageURLs = () => {
  const appEnv = useAppEnv();

  const flightsImagesUrl =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com/assets/images/flights/'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/flights/';

  const flightsCheckoutImagesUrl =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com/assets/images/checkout/'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/checkout/';

  const missingHotelImagesCDNUrl =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com/assets/images/hotels_missing_images/'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/hotels_missing_images/';

  const fallbackHotelImages = [`${missingHotelImagesCDNUrl}bell.jpg`];
  const fallbackRoomImages = [`${missingHotelImagesCDNUrl}pillow.jpg`];

  return {
    flightsImagesUrl,
    flightsCheckoutImagesUrl,
    fallbackHotelImages,
    fallbackRoomImages,
  };
};

export const useProductImageURLs = () => {
  const appEnv = useAppEnv();

  const appleFallbackImage =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com//assets/images/rc-us/notifications/apple-logo.png'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/rc-us/notifications/apple-logo.png';

  return {
    appleFallbackImage,
  };
};

export const PRODUCT_LOGOS_OPTIONS = {
  width: 500,
  auto: 'format',
  fit: 'contain',
};

interface GenerateImageUrlProps {
  url: string;
  width?: number;
  height?: number;
  fit?: string;
  format?: string;
  auto?: string;
}

export function generateImageUrl({
  url,
  width,
  height,
  fit,
  format,
  auto,
}: GenerateImageUrlProps) {
  try {
    const newUrl = new URL(url);
    if (width) {
      /* fixme(khoi): To remove w and h attributes
       * https://github.com/Kaligo/rewards-central/pull/2024/files#r1668331620
       */
      newUrl.searchParams.set('w', width.toString());
      newUrl.searchParams.set('width', width.toString());
    }
    if (height) {
      newUrl.searchParams.set('h', height.toString());
      newUrl.searchParams.set('height', height.toString());
    }
    if (fit) {
      newUrl.searchParams.set('fit', fit);
    }
    if (format) {
      newUrl.searchParams.set('format', format);
    }

    // This is ImgIx supported params, it will automatically convert the image to avif/webp if the browser supports it
    newUrl.searchParams.set('auto', 'format' || auto);

    return newUrl.toString();
  } catch (error) {
    console.error('Error generating image url', url, error);
    return '';
  }
}

interface GenerateImagesUrlProps extends Omit<GenerateImageUrlProps, 'url'> {
  urls: string[];
}

export function generateImagesUrl({ urls, ...props }: GenerateImagesUrlProps) {
  return urls.map((url) => generateImageUrl({ url, ...props }));
}
